import React from 'react';
import './App.css';
import Home from './layout';

function App() {
  return (
    <Home/>
  );
}

export default App;
